import 'antd/dist/antd.css';
import './index.css';
const React = require('react');
const ReactDOM = require('react-dom');

const isInternetExplorer = require("./helper/isInternetExplorer").default;

if(isInternetExplorer()){
    ReactDOM.render(<div style={{minHeight: '100vh', minWidth: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <h2>Internet Explorer에서는 작동이 안됩니다. Chrome 브라우저로 써주시길 바랍니다.</h2>
    </div>, document.getElementById('root'));
}
else {
    const Routes = require('./routes.js').default;
    const firebase = require("firebase");
    const store = require("./redux/store").default;
    const {signIn, signOut} = require("./redux/auth/main");
    const axios = require('axios');

// Your web app's Firebase configuration
    let firebaseConfig = {
        apiKey: "AIzaSyBiY2PhWMzYs6scOkoB_3hy8i5K4AvvhXw",
        authDomain: "khra-backend-3.firebaseapp.com",
        databaseURL: "https://khra-backend-3.firebaseio.com",
        projectId: "khra-backend-3",
        storageBucket: "khra-backend-3.appspot.com",
        messagingSenderId: "975245479880",
        appId: "1:975245479880:web:3f3da78103b1e721237afd"
    };

// Initialize Firebase
    firebase.initializeApp(firebaseConfig);

    const setAuthHeaders = async () => {
        try {
            const token = await firebase.auth().currentUser.getIdToken();
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        } catch (err) {
            console.log(err)
        }
    }

    firebase.auth().onAuthStateChanged(async function(user) {
        if (user) {
            console.log('logged in...', user);
            if(!firebase.auth().currentUser) {
                console.log('app logged out right away!');
                return;
            }

            await setAuthHeaders();

            firebase.auth().currentUser.getIdTokenResult()
              .then((idTokenResult) => {
                  console.log(idTokenResult.claims);
                  // Confirm the user is an Admin.
                  if (!!idTokenResult.claims.admin) {
                      // Show admin UI.
                      store.dispatch(signIn({admin: true, ...user}));
                  } else {
                      store.dispatch(signIn({admin: false, ...user}));
                  }
              })
              .catch((error) => {
                  console.log(error);
              });
        } else {
            console.log('logged out...');
            store.dispatch(signOut());
        }
    });

    firebase.auth().onIdTokenChanged(async function(user) {
        if (user) {
            await setAuthHeaders();
        }
    });

    ReactDOM.render(<Routes />, document.getElementById('root'));
}