import React from 'react';
import {BrowserRouter, Route, Switch, Link} from 'react-router-dom';
import {Provider} from 'react-redux';
import store from './redux/store';
import {
    CSSTransition,
    TransitionGroup,
} from 'react-transition-group';
import qs from 'qs';
import {Layout} from 'antd';
import Loadable from 'react-loadable';
const { Header, Footer, Content } = Layout;

function Loading() {
    return <div style={{minHeight: '100vh', minWidth: '100vw', background: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <h1>불러오는중...</h1>
    </div>;
}

const Admin = Loadable({
    loader: () => import('./pages/admin/main'),
    loading: Loading,
});

const KHRA = Loadable({
    loader: () => import('./pages/khra/main'),
    loading: Loading,
});

const Home = Loadable({
    loader: () => import('./pages/home'),
    loading: Loading,
});

const Result = Loadable({
    loader: () => import('./pages/result'),
    loading: Loading,
});

const Test = Loadable({
    loader: () => import('./pages/test/main'),
    loading: Loading,
});

const Login = Loadable({
    loader: () => import('./pages/login'),
    loading: Loading,
});

const NavBar = Loadable({
    loader: () => import('./components/navbar/main'),
    loading: Loading,
});

export default class PureRoutes extends React.Component{

    state = {
        route: ''
    }

    render(){

        const parsed = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        const naked = parsed.version === 'naked';

        return(
            <BrowserRouter>
                <Provider store={store}>
                   <Layout className="layout" style={{background: '#fff', minHeight: '100vh'}}>
                       {!naked ? <Header style={{background: '#fff', boxShadow: '0 2px 8px #f0f1f2', zIndex: 10}}>
                            <span style={{float: 'left'}} onClick={() => console.log(this.props)}>
                                <Link to='/'>
                                    <h1 style={{ fontWeight: 'bold', fontSize: 20 }}>소통人</h1>
                                </Link>
                            </span>
                           <NavBar location={this.state.route}/>
                       </Header> : ''}
                          <Content id='container-master'>
                            <Route render={({location}) => {
                                if(location !== this.state.route) {
                                    this.setState({route: location});
                                    window.scrollTo(0, 0)}
                                return <TransitionGroup style={{flex: 1}}>
                                    <CSSTransition
                                        key={location.pathname}
                                        classNames="fade"
                                        timeout={300}>
                                        <Switch location={location}>
                                            <Route exact path="/khra" render={(props) => <KHRA {...props} />}/>
                                            <Route path="/khra/:id" render={(props) => <KHRA {...props} />}/>
                                            <Route exact path="/admin" render={(props) => <Admin {...props} />}/>
                                            <Route exact path="/admin/:id" render={(props) => <Admin {...props} />}/>
                                            <Route exact path="/" render={(props) => <Home {...props} />}/>
                                            <Route exact path="/result/:id?" render={(props) => <Result {...props} />}/>
                                            <Route exact path="/test" render={(props) => <Test {...props} />}/>
                                            <Route exact path="/test/:id" render={(props) => <Test {...props} />}/>
                                            <Route exact path="/login" render={(props) => <Login {...props} />}/>
                                            <Route render={(props) => <Missing {...props}/>}/>
                                        </Switch>
                                    </CSSTransition>
                                </TransitionGroup>
                            }}/>
                        </Content>
                        {!naked ? <Footer style={{ textAlign: 'center', display: 'flex', justifyContent:'center', backgroundColor:'#f0f2f5', width:'100%'}}>
                            copyright© 2017-2019 cocogroup.or.kr all rights reversed. SINCE 2017<br/>
                            온라인 및 오프라인 (인성직무적성)검사 신청 문의(기업/개인)<br/>
                            대표번호 : 070-7795-1891 / Email : pertea1213@gmail.com<br/>
                        </Footer> : ''}
                    </Layout>
                </Provider>
            </BrowserRouter>

        )
    }
}

const Missing = (props) => (
    <Layout className="layout">
        <Content style={{ padding: '0 50px' }}>
            <div style={{ background: '#fff', padding: 24, minHeight: 280, marginTop: 16 }}>이페이지는 없는 페이지입니다</div>
        </Content>
    </Layout>
)
